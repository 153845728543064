// src/components/AITutor.js
import React from 'react';

const AITutor = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>COMING SOON</h1>
    </div>
  );
};

export default AITutor;
